<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-9 col-12">Audio Call Campaign Logs<span class="log-header-sublabel ms-3">- {{
      campaigndetails.co2 ? campaigndetails.co2 : "N/A" }}</span></div>
    <div class="
            col-lg-3 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
          
      <div class="service-plan-box-outer">
        <div class="d-flex justify-content-between align-items-center">
          <div class="call-label">Count : </div>
          <div class="call-count ps-1">
            {{ totalRecords ? totalRecords : "0" }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ticket-history-box-outer mb-3" style="min-height: 118px;">
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-2">
          <label class="form-label">Scheduled at Date & Time</label>
          <div class="from-label-value">
            {{ format_timestamp(campaigndetails.co12) }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-2">
          <label class="form-label">Scheduled for Date & Time</label>
          <div class="from-label-value">
            {{ format_timestamp(campaigndetails.co5) }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="customer-label-group mb-0">
          <div v-if="campaigndetails.cl3 != null && campaigndetails.cl3 != ''"><audio controls controlsList="download"
              style="height: 35px !important;">
              <source
                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/callcampaign/' + campaigndetails.cl3"
                type="audio/mpeg" />
            </audio>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="customer-label-group mb-2">
          <button v-if="campaigndetails.cl11 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl9
    }}</span>- {{
        campaigndetails.cl10 }}</button>
          <button v-if="campaigndetails.cl14 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl12
    }}</span>- {{
        campaigndetails.cl13 }}</button>
          <button v-if="campaigndetails.cl17 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl15
    }}</span>- {{
        campaigndetails.cl16 }}</button>
          <button v-if="campaigndetails.cl20 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl18
    }}</span>- {{
        campaigndetails.cl19 }}</button>
          <button v-if="campaigndetails.cl23 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl21
    }}</span>- {{
        campaigndetails.cl22 }}</button>
          <button v-if="campaigndetails.cl26 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl24
    }}</span>- {{
        campaigndetails.cl25 }}</button>
          <button v-if="campaigndetails.cl29 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl27
    }}</span>- {{
        campaigndetails.cl28 }}</button>
          <button v-if="campaigndetails.cl32 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl30
    }}</span>- {{
        campaigndetails.cl31 }}</button>
          <button v-if="campaigndetails.cl35 == 1" type="button"
            class="btn modal-bulk-next-btn my-1 text-capitalize me-2" disabled><span class="me-2 fw-bold">{{
      campaigndetails.cl33
    }}</span>- {{
        campaigndetails.cl34 }}</button>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 275px)">
    <DataTable :value="campaignLogList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading" dataKey="cp1">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="name" header="Voter Name" headerStyle="width: 25%" bodyStyle="width: 25%">
        <template #body="{ data }">
          <div>
            <div class="text-capitalize">
              {{ data.cp7 ? data.cp7 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="votermobile" header="Voter Mobile No." headerStyle="width: 30%" bodyStyle="width: 30%">
        <template #body="{ data }">
          <div>
            {{ data.cp8 ? data.cp8 : "N/A" }}
          </div>
        </template>
      </Column>
      <Column field="statusbutton" header="Response Name" headerStyle="width: 30%" bodyStyle="width: 30%"
        class="d-flex justify-content-center">
        <template #body="{ data }">
          <div>
            <span v-if="data.cp12 == 1">
              <span>{{ data.cp33 }}</span></span>
            <span v-if="data.cp12 == 2">
              <span>{{ data.cp36 }}</span></span>
            <span v-if="data.cp12 == 3">
              <span>{{ data.cp39 }}</span></span>
            <span v-if="data.cp12 == 4">
              <span>{{ data.cp42 }}</span></span>
            <span v-if="data.cp12 == 5">
              <span>{{ data.cp45 }}</span></span>
            <span v-if="data.cp12 == 6">
              <span>{{ data.cp48 }}</span></span>
            <span v-if="data.cp12 == 7">
              <span>{{ data.cp51 }}</span></span>
            <span v-if="data.cp12 == 8">
              <span>{{ data.cp54 }}</span></span>
            <span v-if="data.cp12 == 9">
              <span>{{ data.cp57 }}</span></span>
            <span v-if="data.cp12 == null">N/A</span>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import { useRoute } from "vue-router";
import moment from 'moment';
export default {
  data() {
    return {
      campaignLogList: [],
      campaigndetails: '',
      totalRecords: 0,
      loading: false,
      voterstorageimgpath: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    const route = useRoute();
    this.routeParam = route.params.campaignId;
    this.loading = true;
    this.getcampaignlogs({ co1: this.routeParam });
    if (localStorage.user) {
      this.client_info = JSON.parse(localStorage.client_info);
      this.voterstorageimgpath = this.client_info.maa24;
    }
  },
  beforeUnmount() {
    clearInterval(this.timer5);
  },
  methods: {
    getcampaignlogs(item) {
      this.ApiService.getcampaignlogs(item).then((data) => {
        if (data.success === true) {
          this.campaignLogList = data.data;
          this.campaigndetails = data.campaign_details;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.campaignLogList = null;
          this.campaigndetails = '';
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getcampaignlogs({ page_no: this.page_no, co1: this.routeParam });
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return '-';
      }
    },
  },
};
</script>
<style scoped>
.box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
}

.card-template {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c1d4f3;
  border-radius: 4px;
  padding: 14px 15px 0px 15px;
}

.overlay-reg-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 16px;
  margin-bottom: 6px;
}

.log-header-sublabel {
  font-family: "AcuminPro-SemiBold";
  font-size: 17px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 6px 10px;
}

.service-plan-box-outer .call-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.service-plan-box-outer .call-count {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 10px;
  font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}
</style>